.account-avatar {
    position: relative;
    height: 110px;
    width: 110px;
    margin-bottom: -3.5rem;
    margin-top: 115px;

    input[type="file"] {
        opacity: 0;
        visibility: hidden;
        height: 0;
        width: 0;
    }

    i {
        border-radius: 999px;
        border: 1px solid var(--Neutral-border, #E7E7E9);
        background: #FFF;
        bottom: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        right: 10px;
        height: 26px;
        width: 26px;

        &:before {
            background: url("/img/camera.svg") center no-repeat;
            content: '';
            display: block;
            height: 16px;
            width: 16px;
        }
    }
}

.modal {
    &.account {
        .modal-dialog {
            max-width: 850px;

        }
    }
}
.account-content {
    .box-with-shadow {
        box-shadow: 0 4px 16px 0 rgba(24, 27, 47, 0.04);
    }

    .modal-dialog {
        max-width: 850px;
        margin-left:auto;
        margin-right: auto;
        margin-bottom:2rem;
    }

    #coverImageModal {
        .user-modal-header-bg {
            background: url('/img/user/user_header_bg.png') center/cover;
            padding: 47px 52px 0;
            .transparent-logo {
                position: absolute;
                bottom:-36px;
                left:20px;
                width: 72px;
                height: 72px;
                background-color: rgba(255, 255, 255, 0.40);
                border: 1px solid #fff;
                border-radius: 50px;
            }
            .user-bg-demo {
                border-radius: 4px 4px 0 0;
                img {
                    object-fit: cover;
                    height:125px;
                    width: 100%;
                }

            }
        }
        .bg-images-grid {
            display: flex;
            flex-wrap: wrap;
            gap:10px;
            &>div {
                width: calc((100% - 50px)/6);
                height:120px;
                display:flex;
                label {
                    border-radius: 16px;
                    overflow: hidden;
                    display: flex;
                    img {
                        object-fit: cover;
                    }
                }
                &.checked:after {
                    content: url('/img/user/check_icon.svg');
                    position: absolute;
                    background-color: #fff;
                    border-radius: 50px;
                    right: 6px;
                    top: 7px;
                    width: 16px;
                    height:16px;
                }

            }

            [type=radio] {
                position: absolute;
                opacity: 0;
                width: 0;
                height: 0;

                & + img {
                    cursor: pointer
                }

                &:checked + img {
                    border-radius: 16px;
                    border: 3px solid #6F6FD2;

                    &:before {
                        content: '';
                        position: absolute;
                    }
                }
            }

        }
        .primary-background-strong:active {
            color: #143be7;
            background-color: #6f6fd240;
        }
    }
}
.recommend_interests {
    div {
        display: inline-block;
        label {
            display: inline-block;
            padding: 8px 8px 8px 8px;
            border-radius: 100px;
            background: var(--primary-background, rgba(111, 111, 210, 0.08));
            margin-right: 10px;
            margin-bottom: 10px;
            color: var(--neutral-text, #181B2F);

            font-size: 12px;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            img {
                display: inline-block;
                margin-right: 8px;
            }
        }
        input[type="checkbox"] {
            display:none;
            &:checked + label {
                //background-color: #f00;
            }
        }
    }


}
#edit-account {
    input , textarea {
        border-radius: 8px;
    }
    .invalid-feedback {
        position: absolute;
    }
}

.fade{
    transition: opacity 0.15s linear;
}
.fade:not(.show){
    opacity: 0;
}
.modal{
    z-index: 1055;
}
.modal-dialog{
    margin: 0.5rem;
}
.modal.fade .modal-dialog{
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}
.modal.show .modal-dialog{
    transform: none;
}
.modal.modal-static .modal-dialog{
    transform: scale(1.02);
}
.modal-dialog-scrollable{
    height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content{
    max-height: 100%;
    overflow: hidden;
}
.modal-dialog-scrollable .modal-body{
    overflow-y: auto;
}
.modal-dialog-centered{
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}
.modal-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}
.modal-backdrop.fade{
    opacity: 0;
}
.modal-backdrop.show{
    opacity: 0.5;
}
.modal-header .btn-close{
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
}
.modal-body{
    flex: 1 1 auto;
}
.modal-fullscreen .modal-content{
    height: 100%;
    border: 0;
    border-radius: 0;
}
.modal-fullscreen .modal-header{
    border-radius: 0;
}
.modal-fullscreen .modal-body{
    overflow-y: auto;
}
.modal-fullscreen .modal-footer{
    border-radius: 0;
}
@media (min-width: 576px){

    .modal-dialog{
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable{
        height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered{
        min-height: calc(100% - 3.5rem);
    }
    .modal-sm{
        max-width: 300px;
    }

}
.btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}
.collapse {
    visibility: visible !important;
}
.collapse:not(.show){
    display: none;
}
