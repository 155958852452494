.section-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  background: linear-gradient(244deg, #D1D5FA -7%, #A9DFE2 122.83%);
}

.cart-grid {
  @apply grid;
  @apply grid-cols-6;

  span:first-child {
    grid-column: 1 / 4;
  }

  .grid-title {
    padding-bottom: 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    @apply text-dark-gray;
    border-bottom: 1px solid #888B93;
  }

  .grid-line {
    margin-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #D0D2D9;
  }

  .sub-line {
    margin-top: 16px;
    // padding-bottom: 16px;
    // border-bottom: 1px solid #D0D2D9;
  }

  .cart-product-name {
    grid-column: 2 / 4;
    padding-left: 32px;
    padding-right: 12px;
    display: grid;
    align-items: center;
  }

  .cart-price {
    display: flex;
    align-items: center
  }

  .cart-total,
  .cart-counter {
    font-weight: 700;
    display: flex;
    align-items: center
  }

  .cart-counter {

    span:first-child,
    span:last-child {
      font-weight: 400;
      font-size: 16pt;
    }
  }

  img {
    height: 100px;
    width: 100px;
    border-radius: 12px;
    object-fit: cover;
  }
}

.summary {
  padding: 24px;
  min-width: 370px;
  height: fit-content;
  background-color: white;
  box-shadow: 0px 4px 60px 20px rgba(165, 141, 111, 0.1);
  border-radius: 12px;
}

.summary-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

.summary-total {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.summary-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.deliver_now_card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 20px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0px 4px 60px 20px rgba(165, 141, 111, 0.1);
}

.deliver_now_card.edit {
  width: 100%;
}

.deliver_now_card.edit input {
  height: 40px;
  margin-bottom: 8px;
}

.deliver_now_card.edit select {
  height: 40px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.deliver_after_card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 15px 20px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0px 4px 60px 20px rgba(165, 141, 111, 0.1);
}

.deliver_after_card th {
  width: 140px;
  @apply text-700-16-20;
  color: #888B93;
  text-align: start;
  padding-bottom: 12px;
}

.deliver_after_card td {
  @apply text-400-16-20;
  padding-bottom: 12px;
}

.deliver_after_card_image {
  max-width: 130px;
  max-height: 100px;
  display: flex;
}

.deliver_after_card_image img {
  object-fit: contain;
}

.payment-box {
  // background: linear-gradient(288.27deg, #6F88FC -14.31%, #D1D5FA 92.27%);
  background-color: #FFF;
  border-radius: 12px;
  padding: 24px;
}

.cart-counter-input {
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-items: center;
  border: 0px;
  width: 50px;
  background: inherit;

  -moz-appearance: textfield;
}

.cart-counter-input::-webkit-outer-spin-button,
.cart-counter-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-table {
  min-width: 65%;
  margin-right: 78px;
}

.credit-box {
  border-radius: 4px;
  border: 1px solid var(--Solitude, #E5E8EF);
  padding: 8px 12px;
  margin-top: 8px;
}
