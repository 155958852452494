.knowledge_table {
    width: 100%;
    border-collapse: collapse;
}

.knowledge_table tbody {
    width: 100%;
}

.knowledge_table tr {
    border-bottom: 1px solid #D0D2D9;
    width: 100%;
}

.knowledge_table tr:last-child {
    border-bottom: 0px;
}

.knowledge_table th {
    @apply text-700-16-20;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
    padding-bottom: 16px;
    text-align: left;
    color: #888B93;
    border-bottom: 1px solid #888B93;
}

.knowledge_table td {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 16px;
    padding-bottom: 16px;
    @apply text-400-16-20;
    border-top: 1px solid #D0D2D9;
}

.knowledge_video_preview {
    border-radius: 12px;
    height: 100px;
    width: 140px;
}

.edit_knowledge_video_preview {
    border-radius: 12px;
    width: 230px;
}

.knowledge_video_preview_button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    @apply absolute;
}

.knowledge_menu_border {
    position: relative;
    background-color: #D0D2D9;
    height: 2px;
    width: 100%;
}

.active_border {
    position: absolute;
    background-color: #6F88FC;
    height: 3px;
    width: 70px;
}

.active_menu {
    color: #6F88FC;
}

.inactive_menu {
    color: #D0D2D9;
}

.inactive_menu:hover {
    color: #888B93;
    cursor: pointer;
}

.ml-87 {
    margin-left: 87px;
}

.custom-dropdown-menu li {
    list-style: none;
    position: relative;
}

.custom-dropdown-menu th {
    text-align: left;
}

.custom-dropdown-menu .menu-link {
    color: #888B93;
}

.custom-dropdown-menu .knowledge_submenu-list {
    white-space: nowrap;
    display: none;
    position: absolute;
    right: -25px;
    top: -60px;
    padding: 12px;
    background-color: #FFF;
    border: 1px solid #D0D2D9;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.253);
    border-radius: 4px;
}

.custom-dropdown-menu .menu-list>li:hover .knowledge_submenu-list {
    display: block;
}

.custom-dropdown-menu .knowledge_submenu-link {
    color: #D0D2D9;
    font-weight: 400;
    font-size: 14px;
    padding: 4px;
}

.custom-dropdown-menu .knowledge_submenu-link:hover {
    --tw-text-opacity: 1;
    color: rgb(111 136 252/var(--tw-text-opacity));
}

.rounded-2 {
    border-radius: 2px;
}

.max-w-230 {
    max-width: 230px;
}

.video_processing {
    width: 230px;
    min-height: 150px;
    background: linear-gradient(244deg, #D1D5FA -7%, #A9DFE2 122.83%);
    border-radius: 12px;
    margin-bottom: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading-spin {
    animation: spin 2s linear infinite;
}

.visibility_label {
    margin-left: 26px;
    @apply text-400-12-16;
}


.edit_knowledge_video_thumb {
    border-radius: 12px;
    max-height: 150px;
}