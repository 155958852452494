body {
    @apply color-black;
}

// Headers
h1 {
    font-family: Inter;
    font-size: 32px;
    line-height: 40px;
    @apply font-semibold;
    @apply color-black;
}

h2 {}

h4 {
    font-family: Inter;
    @apply text-base;
    @apply font-normal;
    color: var(--Neutral-text-weak, #767676);
}

// Link
// a:hover {
//     color: #5959a8;
// }

// a:link {
//     color: #dfe20f;
// }
// a:visited {
//     color: #0f12d6;
// }
// a:hover {
//     color: #e00f5f;
// }
// a:active {
//     color: #0fd31f;
// }

// Backgrounds
.bg-main {
    background-color: #f7f7f6;
}

.bg-lights {
    background: var(--gradient-card, linear-gradient(180deg, #DCDCF2 0%, #F2E4DC 100%));
}

.bg-l-gray {
    background-color: #f4f4fc;
}

// Height
.h-dvh {
    height: 100vh;
}

// Width
.w-42 {
    width: 42%;
}

.w-58 {
    width: 58%;
}


// Custom text
.custom-text-xs {
    font-size: 11px;
    line-height: 12px;
}

.custom-text-xl {
    font-size: 50px;
    line-height: 52px;
}

// Buttons

.button-m {
    border-radius: 8px;
    background: var(--Primary-background-strong, #6F6FD2);
    transition: background-color 0.3s ease-in-out;
    @apply text-sm;
    @apply font-semibold;
    padding: 10px 20px 10px 20px;
    color: #FFF;
}

.button-m:hover {
    background: var(--Primary-background-strong, #5959A8);
}

.button-m:active {
    background: var(--Primary-background-strong, #43437E);
}

.button-m:disabled {
    background: var(--Primary-background-strong, #f6f6f7);
    color: #9798A1;
}

.button-m-light {
    border-radius: 8px;
    background: var(--Primary-background-strong, #f4f4fc);
    transition: background-color 0.3s ease-in-out;
    @apply text-sm;
    @apply font-semibold;
    padding: 10px 20px 10px 20px;
    color: #6F6FD2;
}

.button-m-light:hover {
    background: var(--Primary-background-strong, #dbdbf3);
    color: #5959a8;
}

.button-m-light:active {
    background: var(--Primary-background-strong, #cdcdef);
}

.button-m-light:disabled {
    background: var(--Primary-background-strong, #f6f6f7);
    color: #9798A1;
}

// Text Colors
.color-black {
    color: var(--Neutral-text, #181B2F);
}

.color-gray {
    color: var(--Neutral-text-weak, #565967);
}

.color-light-gray {
    color: var(--Neutral-text-weak, #9798A1);
}

.color-primary {
    color: var(--Primary-text, #3557EE);
}

.color-primary-hover:hover {
    color: var(--Primary-text-hover, #5959A8);
}

.color-primary-text {
    color: #6f6fd2;

    &:hover {
        color: #5959a8;
    }
}

.netural-text-weak {
    color: var(--Neutral-text-weak, #565967);
}

.neutral-bg-medium {
    background-color: #f7f7f6;

}

.neutral-bg-base-40 {
    background-color: rgba(255, 255, 255, 0.4);
}

//Borders
.neutral-border {
    border: 1px solid var(--Neutral-border, #E7E7E9);
}

// Shadows

.shadow-1 {
    box-shadow: 0px 4px 16px 0px rgba(24, 27, 47, 0.04);
}

// Gradients
.custom-gradients-block {
    @apply flex;
    @apply bg-lights;
    @apply rounded-2xl;
    @apply relative;
    @apply overflow-hidden;
}

.ray-light {
    left: 40%;
    z-index: 0;
    width: 116px;
    height: 1005.096px;
    transform: rotate(-45deg);
    filter: blur(50px);
}

.light-1 {
    top: -300px;
    background: linear-gradient(180deg, #C7C5FA 0%, rgba(199, 197, 250, 0.00) 100%);
    opacity: 0.6;
}

.light-2 {
    top: -150px;
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    opacity: 0.6;
}

.light-3 {
    top: 0px;
    background: linear-gradient(180deg, #F4C9F5 0%, rgba(244, 201, 245, 0.00) 100%);
    opacity: 0.6;
}

.light-4 {
    top: 150px;
    opacity: 0.15;
    background: linear-gradient(180deg, #F0EBC3 0%, rgba(240, 235, 195, 0.00) 100%);
}

.light-5 {
    top: 300px;
    opacity: 0.5;
    background: linear-gradient(180deg, #C7C5FA 0%, rgba(199, 197, 250, 0.00) 100%);
}

// Checkboxes
.checkbox-label {
    display: block;
    padding-left: 28px;
    position: relative;
}

input[type="checkbox"] {
    @apply focus:ring-0;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    transition: background-color 0.3s ease-in-out;
    margin: 0;

    font: inherit;
    color: #6F6FD2;
    width: 20px;
    height: 20px;
    border: 0.15em solid #E7E7E9;
    transition: border 0.3s ease-in-out;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;

    &.rounded-full {
        border-radius: 50%;
    }
}

.form-control+.form-control {
    margin-top: 1em;
}

input[type="checkbox"]::before {
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
}

input[type="checkbox"]:hover {
    background-color: #F6F6F7;
    border: 0.15em solid #C5C6CB;
    color: #5959A8;
}

input[type="checkbox"]:checked::hover {
    transform: scale(1);
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

@media screen and (max-width: 768px) {
    .h-dvh {
        height: unset;
    }
}

.button-tertiary {
    color: #181B2F;
    border-radius: var(--8-px-05-rem, 8px);
    background: white;
    border: var(--0px, 1px) solid var(--Neutral-border-hover, #DCDDE0);
}

.button-tertiary:hover {
    background: var(--Neutral-background-hover, #F6F6F7);
    border: 1px solid var(--Neutral-border-active, #C5C6CB);
}

.button-tertiary:focus {
    background: var(--Neutral-background-active, #DCDDE0);
    border: 1px solid var(--Neutral-border-active, #C5C6CB);
}

.button-tertiary:disabled {
    color: var(--Neutral-text-disabled, #9798A1);
    background: #ffffff;
    border: var(--0px, 1px) solid var(--Neutral-border, #E7E7E9);
}

.btn-plan {
    &.active {
        svg.icon-svg.w-4.h-4  {
            @apply inline-block;
        }
    }
}