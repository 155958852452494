.section-bg-steps {
  @apply relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 30%;
    bottom: 0;
    @apply rounded-xl;
  }

  &.section-step-1 {
    &:before {
      background: linear-gradient(244deg, #D1D5FA -7%, #A9DFE2 122.83%);
    }
  }

  &.section-step-2 {
    &:before {
      background: linear-gradient(288.27deg, #6F88FC -14.31%, #D1D5FA 92.27%);
    }
  }

  &.section-step-3 {
    &:before {
      background: linear-gradient(244.45deg, #6F88FC -6.98%, #A9DFE2 114.63%);
    }
  }
}

.block-video {
  @apply relative;

  img {
    @apply rounded-xl;
  }

  .play-button {
    background: url(../../svg/btn-play.svg) center no-repeat;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    @apply absolute;
    @apply w-25;
    @apply h-25;
  }
}

.title-line {
  @apply relative;
  @apply pl-15;

  &:before {
    @apply bg-indigo;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 25px;
    height: 1px;
    width: 50px;
  }
}

.store-option {
  @apply flex items-center;
  @apply bg-white w-full;
  @apply font-bold;
  @apply rounded;
  @apply p-3.5;
  padding: 14px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;

  i {
    @apply mr-1.5;
    @apply text-2xl;
    @apply text-indigo;
  }
}

.modal-video {
  max-width: 1000px;
  margin: 30px auto;
}

.modal-video-body {
  position: relative;
  padding: 0px;
}

.modal-video-close {
  position: absolute;
  right: -30px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1;
}
