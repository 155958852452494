/* cars page */
.account-content {
    .car-img-slider {
        .slick-dotted.slick-slider {
            margin:0;
        }
        .slick-dots {
            bottom: 16px;
            li {
                width:unset;
                height:unset;
                button {
                    position: relative;
                    width: unset;
                    height: unset;
                    &:before {
                        content: '';
                        width:5px;
                        height:5px;
                        border-radius:50%;
                        background-color: rgba(255,255,255,0.4);
                        opacity:1;
                    }

                }
                &.slick-active {
                    button::before {
                        background-color: #fff;
                    }
                }
            }
        }
    }
    input::placeholder,
    textarea::placeholder {
        color: var(--neutral-text-disabled);
    }
}

/* end cars page */

.belongings-images {
    img {
        max-height: 180px;
    }
}

.accordion-button {
  border-radius: 0.5rem;
  background: #FFFFFF;
  text-align: left;
  font-weight: 600;
  font-size: 24px;
  line-height: 46px;
  display: flex;
  align-items: center;
  color: #000000;
  padding: 12px 24px;
  width: 100%;
  border-width: 0px;
}

.accordion-button::after {
  background-image: url("/img/accordion-down.svg");
  background-size: 24px 24px;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
  color: #000;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("/img/accordion-down.svg");
}

.accordion-title-checked {
  text-align: left;
  display: flex;
  align-items: center;
  color: #000000;
  padding-right: 16px;
  width: 100%;
  border-width: 0px;
}

.accordion-title-checked::after {
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
  content: "";
  background-image: url("/img/icon-check-square.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  transition: transform 0.2s ease-in-out;
}

.accordion-title-x {
  text-align: left;
  display: flex;
  align-items: center;
  color: #000000;
  padding-right: 16px;
  width: 100%;
  border-width: 0px;
}

.accordion-title-x::after {
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
  content: "";
  background-image: url("/img/icon-x-square.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  transition: transform 0.2s ease-in-out;
}

.empty-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #888B93;
  padding-left: 2rem;
}

.percent-left {
  margin-left: -7px;
}

.percent-right {
  margin-right: -20px;
}

/*
 healthcare tabs
*/
.content-tabs {
    li {
        button {
            border-radius: 100px;
            border: 1px solid var(--primary-border-card, rgba(111, 111, 210, 0.25));
            font-size: 12px;
            line-height: 16px;
            &[aria-selected="true"] {
                background-color: var(--primary-background-medium);
                color: var(--neutral-text);
                font-weight: 600;

            }
        }
    }
}
