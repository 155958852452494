// Home page
.deals {
  @apply grid;
  @apply grid-cols-3;
  @apply gap-7.5;

  a:first-child {
    grid-row: span 2 / span 2;

    img {
      height: 430px;
    }
  }
}

.deal {
  @apply relative;
  @apply rounded-xl;
  @apply overflow-hidden;

  &:before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    content: '';
    height: 100%;
    width: 100%;
    z-index: 1;
    @apply absolute;
  }

  h4 {
    @apply absolute;
    @apply text-white;
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    z-index: 2;
  }

  img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
}

.knowledge-video-box {
  @apply grid;
  @apply grid-cols-3;
  @apply gap-7.5;
}

.knowledge-video-img {
  border-radius: 12px;
  height: 180px;
  width: -webkit-fill-available;
  object-fit: cover;
}

.play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  @apply absolute;
}

.blog-link {
  @apply font-bold;
  overflow: hidden;
  white-space: nowrap;

  i {
    @apply text-lg;
    @apply text-indigo;
    padding: 0px;
  }
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.pt-28 {
  padding-top: 7rem;
}


// Home article

.home-article-header {
  @apply relative;
  @apply ml-0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 30%;
    right: 0%;
    bottom: 0;
  }

  &.home-article {
    &:before {
      background: linear-gradient(244deg, #D1D5FA -7%, #A9DFE2 122.83%);
    }
  }
}

.pr-135px {
  padding-right: 135px;
}

.pr-130px {
  padding-right: 130px;
}

.pl-115px {
  padding-left: 115px;
}

.map-left-side {
  height: 100%;
  width: 48%;
}

.social-icon-box {
  width: 24px;
  height: 24px;
  display: flex;
  // align-items: center;
  justify-content: center;
}

.social-icon-box-facebook {
  width: 24px;
  height: 20px;
  display: flex;
  // align-items: center;
  justify-content: center;
}


// Find lawyer

.avatar-90 {
  height: 90px;
  width: 90px;
  border-radius: 50%;
}

.lawyer-contacts-table {
  border-collapse: collapse;
  @apply text-400-14-20;

  th {
    font-weight: 400;
    display: flex;
    @apply text-dark-gray;
    margin-right: 12px;
  }

  .spacer {
    height: 0.5rem;
  }
}

.lawyer-contacts-block {
  width: 100%;
  max-width: 580px;
  margin-top: 280px;
  margin-bottom: 80px;
}

.lawyer-search-block {
  margin-left: 48%;
  margin-right: 24px;
  padding-left: 60px;
  width: 100%;
}

.lawyer-gradient {
  height: 100px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  background: linear-gradient(0deg, rgba(247, 240, 231, 1) 0%, rgba(247, 240, 231, 0) 100%);
}

.lawyer-search-header {
  @apply bg-beige;
  position: fixed;
  padding-top: 80px;
  margin-right: 24px;
  max-width: 580px;
}

////////////////

.min-h-700 {
  min-height: 700px;
}

.mb-42 {
  margin-bottom: 10.5rem;
}


/////////////// Chat

@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}

.circle-22 {
  width: 22px;
  height: 22px;
  background: #6F88FC;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  padding-top: 3px;
  @apply text-400-12-16;
}

.circle-28 {
  width: 28px;
  height: 28px;
  background: #6F88FC;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  padding-top: 5px;
  @apply text-400-16-20;
}

.therapist-profile {
  margin-top: 48px;
  margin-bottom: 32px;
  margin-left: 30px;
  padding: 24px 16px;
  width: 270px;
  border-radius: 12px;
  background: #FFF;
  @apply text-400-16-20;
  text-align: center;
}

.therapist-timeslot {
  margin-top: 12px;
  padding: 16px;
  // width: 300px;
  border-radius: 12px;
  background: #EEF1FF;
}

.therapist-timeslot-selected {
  background: #6F88FC;
}

.therapist-timeslot-selected span,
.therapist-timeslot-selected i {
  color: #FFF;
}

.timeslot-table {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.my-message {
  display: inline-flex;
  float: right;
  @apply place-items-center;
}

.my-message .message {
  margin-right: 16px;
  background: #FFF;
  border-radius: 10px 2px 10px 10px;
  padding: 10px 24px;
}

.my-message .my-second-message {
  margin-right: 45px;
}

.my-message-time {
  float: right;
  color: #888B93;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding-right: 45px;
  padding-top: 5px;
}

.companion-message {
  display: inline-flex;
  float: left;
  @apply place-items-center;
}

.companion-message .message {
  margin-left: 16px;
  border-radius: 2px 10px 10px 10px;
  padding: 10px 24px;
  color: #FFF;
  @apply bg-gradient-in-chat;
}

.companion-message .companion-second-message {
  margin-left: 45px;
}

.companion-message-time {
  float: left;
  color: #888B93;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  padding-left: 45px;
  padding-top: 5px;
}

.chat-message-block {
  position: absolute;
  width: 680px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);

  #message::placeholder {
    color: #D0D2D9;
  }
}

.chat-message-therapist {
  position: relative;
  bottom: -30px;
  margin-left: -60px;
}

@media (max-width: 1440px) {
  .chat-message-block {
    width: 550px;
  }

  .chat-message-therapist {
    margin-left: -20px;
  }
}

@media (max-width: 1170px) {
  .chat-message-block {
    width: 465px;
  }
}

.therapist-border {
  border-left: #6F88FC;
  border-style: solid;
}

.chat-messages {
  max-height: 75%;
  overflow-y: scroll;
}

.file-icon {
  font-size: 20px;
  color: #888B93;
}

.remove-chat-btn {
  position: relative;
  top: -9px;
  left: -23px;
}

.therapist-note {
  border-width: 1px;
  border-color: #D0D2D9;
  border-style: solid;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
}

.therapist-event {
  margin-top: 12px;
  padding: 16px;
  // width: 260px;
  border-radius: 12px;
  background: #EEF1FF;

  span {
    @apply text-400-16-20;
  }
}

.evemt-input {
  width: 40px;
  height: 25px !important;
  padding: 0;
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.timeslot-input {
  width: 60px;
  height: 40px !important;
  padding: 0;
  text-align: center;
  @apply text-400-14-20;
}

.timeslot-select {
  width: 85px;
  height: 40px !important;
  padding: 0;
  padding-left: 5px;
  text-align: center;
  border-color: #6b7280;
  @apply text-400-14-20;
}

.ui-timepicker-container {
  @apply text-400-14-20;
}

.therapist-calendar {
  width: 380px;

  .ui-widget-content {
    border: none;
    width: 380px;
  }

  .ui-widget-header {
    background: none;
    border: none;
  }

  .ui-datepicker-calendar {
    border-collapse: separate;
    border-spacing: 16px 6px;
  }

  .ui-state-default {
    background: #EEF1FF;
    border: none;
    color: #6F88FC;
    border-radius: 50%;
    padding: 6px 7px;
    text-align: center;
  }

  .ui-datepicker-today {
    border: solid 1px #6F88FC;
    border-radius: 50%;
  }

  .ui-state-disabled .ui-state-default {
    background: none;
    border: none;
  }

  .ui-state-active {
    border: none;
    background-color: #6F88FC;
    color: #FFF;
  }

  .ui-state-active:hover {
    color: #FFF;
  }

  .ui-widget-header .ui-icon {
    background-image: none;
  }

  .ui-datepicker .ui-datepicker-next {
    background: url("/img/chevron-right.svg");
    background-repeat: no-repeat;
  }

  .ui-datepicker .ui-datepicker-next:hover {
    border: none;
    cursor: pointer;
  }

  .ui-datepicker .ui-datepicker-prev {
    background: url("/img/chevron-left.svg");
    background-repeat: no-repeat;
  }

  .ui-datepicker .ui-datepicker-prev:hover {
    border: none;
    cursor: pointer;
  }
}

.therapist-canvas {
  width: 100%;
}