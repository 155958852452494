// .block-image {
//     img {
//         @apply rounded-xl;
//         max-width: 370px;
//         height: auto;
//         max-height: 370px;
//     }
// }

.persons-table {
    margin-bottom: 10px;
    margin-top: 20px;
    width: 100%;

    thead {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        border-bottom: 1px solid #888B93;

        th {
            color: #888B93;
            padding-bottom: 12px;
            float: left;
        }
    }

    tbody {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        tr {
            border-bottom: 1px solid #D0D2D9;

            td {
                padding: 10px 0;
            }
        }
    }
}

.guards-table {
    @extend %responive-table;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 100%;

    thead {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        border-bottom: 1px solid #888B93;

        th {
            color: #888B93;
            // padding-bottom: 12px;
        }

        .th2 {
            font-size: 14px;
            padding-top: 4px;
            padding-bottom: 8px;
        }
    }

    tbody {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        tr {
            border-bottom: 1px solid #D0D2D9;

            td {
                padding: 10px 0;
            }
        }

        .delete-button {
            color: #888B93;
            font-size: 12px;
        }
    }
}

.spotify-tracks {
    .track {
        background-color: #fff;
        border-radius: 10px;
        cursor: pointer;
        padding: 10px;
        transition: box-shadow .1s ease-in-out;

        &.active,
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 15px;
        }
    }

    .button-play {
        border: 0;
        background: transparent;
        box-sizing: border-box;
        width: 0;
        height: 16px;
        width: 15px;
        border-color: transparent transparent transparent #202020;
        transition: 100ms all ease;
        cursor: pointer;

        border-style: solid;
        border-width: 9px 0 9px 14px;

        &.play {
            border-style: double;
            border-width: 0px 0 0px 11px;
        }

        &:hover {
            border-color: transparent transparent transparent #404040;
        }
    }
}

.track {
    b {
        overflow-wrap: anywhere;
    }

    img {
        object-fit: cover;
        height: 50px;
        border-radius: 4px;
        width: 50px;
    }
}