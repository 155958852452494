#editEulogistModal .modal-content {
  height: 370px !important;
}

#editDirectorModal .modal-content {
  height: 370px !important;
}

#editEmergencyModal .modal-content {
  height: 370px !important;
}