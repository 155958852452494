.cemetery-mausoleum-button {
  display: flex;
  width: 470px;
  height: 437px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  background-color: #FFF;
  border-radius: 8px;
  padding: 24px 20px;
  
  cursor: pointer;
}

// .cemetery-mausoleum-button:hover, .cemetery-mausoleum-selected {
//   background: linear-gradient(357.92deg, #6F88FC -23.65%, #D1D5FA 122.67%);

//   h3 {
//     color: #FFF;
//   }

//   img {
//     filter: brightness(1030%) contrast(104%);
//   }
// }

.cemetery-button:hover, .cemetery-selected {
  background-image: url("/img/cemetery-2.png");
  color: #FFF;

  img {
    filter: brightness(1030%) contrast(104%);
  }
}

.mausoleum-button:hover, .mausoleum-selected {
  background-image: url("/img/mausoleum.png");
  color: #FFF;

  img {
    filter: brightness(1030%) contrast(104%);
  }
}

.w-772 {
  width: 772px;
}