.ys-audio-player {
  @apply flex w-full rounded-full p-1;
  background-color: rgba(242, 243, 245, 0.70);
  min-height: 50px;
  align-items: center;

  &-play-button {
    @apply cursor-pointer h-[34px] w-[34px] text-white rounded-full flex items-center justify-center flex-none;
    @apply bg-purple-600;
  }

  &-controls {
    @apply flex justify-between;
  }

  &-volume {
    @apply flex items-center;

    > input {
      @apply absolute w-20 h-3 left-3 hidden;
    }

    &-icon {
      @apply cursor-pointer;
    }

    &-slider {
      @apply cursor-pointer w-full outline-none bg-transparent appearance-none;
    }
  }

  .volume {
    &:hover {
      > input {
        @apply block;
      }
    }
  }

  .timecode{
    span {
      color: #525152
    }
  }
  &-current-time, &-total-duration{
    color: #525152
  }
}

.ys-audio-player-volume input[type="range"]::-webkit-slider-thumb {
  @apply appearance-none h-1 w-1 bg-white rounded-full border-none;
  -webkit-appearance: none;
}
.ys-audio-player-volume input[type="range"]::-moz-range-thumb {
  @apply appearance-none h-1 w-1 bg-white rounded-full border-none;
  -webkit-appearance: none;
}
.ys-audio-player-volume input[type="range"]::-ms-thumb {
  @apply appearance-none h-1 w-1 bg-white rounded-full border-none;
  -webkit-appearance: none;
}
.ys-audio-player-volume input[type="range"]::-webkit-slider-runnable-track {
  @apply h-1 w-full bg-black rounded-md;
}
.ys-audio-player-volume input[type="range"]::-ms-track {
  @apply h-1 w-full bg-black rounded-md text-transparent bg-transparent border-transparent;
}

.audio-recorder {
  margin: 0 auto;
  // height: 60px;
  // min-width: 60px;
  background-color: transparent;

  .audio-recorder-mic{
    margin: 0 auto;
    height: 26px;
  }
}