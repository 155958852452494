.close_icon_style {
    @apply color-black;
    margin-right: 4px;
    margin-top: 2px;
}

.max-w-570 {
    max-width: 570px;
}

.content_table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 12px;
}

.content_table tbody {
    width: 100%;
}

.content_table tr {
    border-bottom: 1px solid #D0D2D9;
    width: 100%;
}

.content_table tr:last-child {
    border-bottom: 0px;
}

.content_table th {
    @apply text-700-16-20;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
    padding-bottom: 16px;
    text-align: left;
    color: #888B93;
    border-bottom: 1px solid #888B93;
}

.content_table td {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 16px;
    padding-bottom: 16px;
    @apply text-400-16-20;
    border-bottom: 1px solid #D0D2D9;
}


[x-show~="popover"] {
    z-index: 2147483647;
    position: fixed;
    top: calc(50% - 10rem);
    left: calc(50% - 8rem);
}

[x-data~="wireui_datetime_picker"] {
    width: 230px;
}

[x-for~="weekDays"] {
    color: black;
}

#date_time button {
    background-color: #888B93;
}

.accordion-title-type {
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: right;
    align-items: center;
    color: #6F88FC;
    padding: 6px 12px;
    border-radius: 20px;
    background: var(--light-purple, #EEF1FF);
    border-width: 0px;
    line-height: 20px;
    margin-right: 10px;
}

.upload-btns {
    position: relative;
    top: 14px;
}