.add-new-button {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  gap: 0.5rem;
  text-transform: uppercase;

  display: flex;
  align-items: center;

  color: #6F88FC;
}