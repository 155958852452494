@keyframes rippleGreen {
    0% {
        box-shadow: 0 0 0 3px rgba(0, 169, 145, 0.4);
        opacity: 1;
    }
    100% {
         box-shadow: 0 0 0 20px rgba(0, 169, 145, 0);
         opacity: 0;
    }
}

@keyframes rippleYellow {
    0% {
        box-shadow: 0 0 0 3px #C69D0B66;
        opacity: 1;
    }
    100% {
         box-shadow: 0 0 0 20px #C69D0B00;
         opacity: 0;
    }
}

.shadow-event-pointer {
    &::after {
        animation: rippleYellow 1.25s infinite ease-out;
    }
}

.shadow-memory-pointer {
    &::after {
        animation: rippleGreen 1.25s infinite ease-out;
    }
}

.shadow-event-pointer,
.shadow-memory-pointer {
    z-index: 1;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        animation-delay: inherit;
        z-index: 0;
    }
}