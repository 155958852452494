// Flowbite
@import 'flowbite';

@tailwind base;
@tailwind components;
@tailwind utilities;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.cdnfonts.com/css/rage-italic');

// Variables
@import 'variables';

// Icons
//@import 'icons';

// Styles for common elements
@import 'defaults';

// Slick slider
@import 'slick/slick';
@import 'slick/slick-theme';

// Select2
@import 'select2.min';

// Custom animations
@import 'animations';

// Components
@import './components/loader';
@import './components/header';
@import './components/forms';
@import './components/lifetime-range';
@import './components/audio-player';
@import './components/redactor';
@import 'redactor/redactor';

// Pages
@import './pages/full-preview';
@import './pages/step-user';
@import './pages/step-flowers';
@import './pages/step-last-video';
@import './pages/step-who-you-know';
@import './pages/step-funeral';
@import './pages/step-cremation-burial';
@import './pages/step-cemetery-mausoleum';

// Dashboard
@import './dashboard/index';

// Custom styles
@import 'custom';

// New design styles
@import 'yourstruly';
@import './pages/login';
