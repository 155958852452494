@layer base {

    html,
    body {
        @apply bg-main;
        font-family: Inter;
        @apply font-normal;
        @apply text-base;
        @apply leading-5;
        @apply font-inter font-normal text-neutral-text antialiased;
    }

    label {
        @apply text-gray-dark;
        @apply text-sm;
    }

    input,
    select,
    textarea {
        @apply border;
        @apply border-gray-light;
        @apply px-3.5 py-2.5;
        @apply placeholder-neutral-weak text-neutral-weak;

        &.is-invalid {
            @apply border-validation;
        }
    }

    input:active,
    input:focus,
    input:focus-within,
    select:active,
    select:focus,
    select:focus-within,
    textarea:active,
    textarea:focus-within,
    textarea:focus {
        @apply outline-none;
        @apply rounded-none;
    }

    input:where(:not([type])):focus,
    [type='text']:focus,
    [type='email']:focus,
    [type='url']:focus,
    [type='password']:focus,
    [type='number']:focus,
    [type='date']:focus,
    [type='datetime-local']:focus,
    [type='month']:focus,
    [type='search']:focus,
    [type='tel']:focus,
    [type='time']:focus,
    [type='week']:focus,
    [multiple]:focus,
    textarea:focus,
    select:focus {
        @apply appearance-none outline-none;
        @apply ring-offset-0 ring-0;
    }

    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    select {
        @apply h-10;
    }

    p {
        @apply leading-5;
        @apply mb-5;
    }

    h1 {
        @apply text-h1;
        @apply mb-6;
    }

    h2 {
        @apply text-h2;
    }

    h3 {
        @apply text-h3;
    }

    h4 {
        @apply text-h4;
        @apply font-semibold;
    }

    h5 {
        @apply font-semibold;
        @apply text-xl;
    }

    h3,
    h6 {
        @apply font-bold;
    }
}

@layer components {
    .btn {
        @apply rounded;
        @apply border;
        @apply border-indigo;
        @apply text-white;
        @apply text-center;
        @apply bg-indigo;
        @apply leading-5;
        @apply flex;
        @apply justify-center;
        @apply uppercase;
        @apply w-fit;

        @apply hover:bg-indigo-dark;
        @apply hover:border-indigo-dark;

        @apply transition-colors;
        @apply ease-in-out;
        @apply duration-300;
        min-width: 140px;
    }

    .btn-white {
        @apply bg-white;
        @apply text-indigo;

        @apply hover:bg-white;
        @apply hover:text-indigo-dark;
        @apply hover:border-indigo-dark;
    }

    .btn-sm {
        @apply text-sm;
        @apply p-btn-sm;
    }

    .btn-md {
        @apply p-btn-md;
    }

    .btn-lg {}

    .invalid-feedback {
        @apply text-xs;

        strong {
            @apply font-normal;
        }
    }
}

svg.icon-svg path {
    fill: currentColor;
}

.ys-sidebar-menu {
    &-item {
        @apply flex;
        @apply pl-5 py-[10px];
        @apply text-sm text-neutral-text;
        @apply rounded-r-full;
        @apply border-none;
        @apply pr-[10px];

        &:hover,
        &.active {
            @apply text-neutral-text;
            @apply bg-purple-600/8;

            svg {
                color: #6F6FD2;
            }
        }

        &.active {
            @apply font-semibold;
        }

        &.close-item {
            span {
                @apply lg:hidden;
            }
        }
    }

    &.short-menu {
        .ys-sidebar-menu-item {
            span {
                @apply lg:hidden;
            }
        }
    }
}

.scroll-snap-x {
    scroll-snap-type: x mandatory;
}

.snap-center {
    scroll-snap-align: center;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.gallery-thumbs-bottom {
    .swiper-slide {
        @apply opacity-40 cursor-pointer;

        &.swiper-slide-thumb-active {
            @apply opacity-100;
        }
    }
}

@media (min-width: 640px) {
    .sm\:order-1 {
        order: 1;
    }

    .sm\:order-2 {
        order: 2;
    }

    .sm\:order-3 {
        order: 3;
    }

    .sm\:order-4 {
        order: 4;
    }
}
