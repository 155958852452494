.login-block {
    width: 380px;
}

.or {
    @apply absolute;
    @apply bg-white;
    @apply top-1;
    @apply px-5;
    left: calc(50% - 28px);
}

input[type="text"].form-input, input[type="password"].form-input ,input[type="email"].form-input, {
    width: 100%;
    @apply rounded-lg;
    height: 40px;
}

input[type="password"].form-input {
    position: relative;
}

.password-eye {
    position: absolute;
    right: 12px;
    bottom: 12px;
}

.avatar-group {
    height: 40px;
    width: 152px;
}
a.social:hover {
    color: inherit;
}
form a.cancel:hover {
    color: #5959a8;
}
a.continue:hover {
    color: #fff;
}

.login-loading-text-rotator {
    width: 600px;
    .item-1,
    .item-2,
    .item-3 {
        position: absolute;
        display: block;
        top: 0;
        animation-duration: 20s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        line-height:28px;
    }
    .item-1{
        animation-name: anim-1;
    }

    .item-2{
        animation-name: anim-2;
    }

    .item-3{
        animation-name: anim-3;
    }
    @keyframes anim-1 {
        0%, 8.3% { top: -100%; opacity: 0; }
        8.3%,25% { top: 25%; opacity: 1; }
        33.33%, 100% { top: 110%; opacity: 0; }
    }

    @keyframes anim-2 {
        0%, 33.33% { top: -100%; opacity: 0; }
        41.63%, 58.29% { top: 25%; opacity: 1; }
        66.66%, 100% { top: 110%; opacity: 0; }
    }

    @keyframes anim-3 {
        0%, 66.66% { top: -100%; opacity: 0; }
        74.96%, 91.62% { top: 25%; opacity: 1; }
        100% { top: 110%; opacity: 0; }
    }
}




