// Custom responsive table
@mixin table-mobile {
    display: block;

    tbody,
    th,
    td,
    tr {
        display: block;
    }

    thead {
        display: none;
    }

    tr {
        float: left;
        width: 100%;
        margin: 0 0 1em;
        border: 1px solid #D0D2D9;
        box-shadow: 0px 2px 5px 0px #D0D2D9;
        border-radius: 4px;

        td {
            padding: .5em .5em .5em 50%;
            float: left;
            width: 100%;

            &:before {
                width: 100%;
                display: block;
                float: left;
                padding: .5em .5em 0 0;
                clear: both;
                margin: -.5em 0 0 -100%;
                font-size: 1.125em;
                color: #888B93;
            }
        }
    }
}

@mixin table-desktop {
    display: table;

    tbody {
        border-bottom: 1px solid #888B93;
    }

    th,
    td {
        display: table-cell;
    }

    th {
        text-align: start;
    }

    tr {
        display: table-row;
        border: none;
        float: none;
        margin: 0;
        box-shadow: none;

        td {
            padding: .75em 0.5rem 0.75em .25em;
            float: none;
            width: auto;

            &:before {
                padding: 0 !important;
            }
        }
    }

    thead {
        display: table-header-group;
    }

    tbody,
    tfoot {
        display: table-row-group;
    }
}

%responive-table {
    @include table-mobile;

    @media screen and (min-width: 1000px) {
        @include table-desktop;
    }
}

@mixin responive-table($headings...) {
    $list: $headings;

    @each $list-headings in $list {
        $i: index($list, $list-headings);

        tbody tr td:nth-of-type(#{$i}):before {
            content: $list-headings;
        }

        @media screen and (min-width: 1000px) {
            tbody tr td:nth-of-type(#{$i}):before {
                content: '';
            }
        }
    }
}

.contacts-table {
    @extend %responive-table;
    @include responive-table('Contact', 'Relation', 'Email', 'Phone Number', 'Address');

    margin-bottom: 30px;
    width: 100%;

    thead {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        border-bottom: 1px solid #888B93;

        th {
            color: #888B93;
            padding-bottom: 12px;
        }
    }

    tbody {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        tr {
            border-bottom: 1px solid #D0D2D9;
        }
    }
}

// Custom dropdown menu
.custom-dropdown-menu {
    li {
        list-style: none;
        position: relative;
    }

    th {
        text-align: left;
    }

    .menu-link {
        color: #888B93;
    }

    .submenu-list {
        display: none;
        position: absolute;
        right: -25px;
        top: -60px;
        background-color: #FFF;
        border: 1px solid #D0D2D9;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.253);
        border-radius: 4px;
    }

    .menu-list>li:hover .submenu-list {
        display: block;
    }

    .submenu-link {
        color: #D0D2D9;
        font-weight: 400;
        font-size: 14px;
    }

    .submenu-link:hover {
        --tw-text-opacity: 1;
        color: rgb(111 136 252 / var(--tw-text-opacity));
    }
}

.menu-import {
    width: 180px;

    .menu-import-item {
        color: #000;
        font-weight: 400;
        font-size: 14px;
        padding: 0.75rem;
    }

    .menu-import-item:hover {
        background-color: rgb(111, 136, 252, 0.25);
        color: #000;
    }
}

.remaining-text {
    font-weight: 700;
    font-size: 12px;
}

.progress-bar {
    margin-bottom: 16px;
    border: 0;
    height: 10px;
    width: 140px;
}

.progress-bar::-webkit-progress-bar {
    background: rgba(208, 210, 217, 0.5);
    border-radius: 6px;
}

.progress-bar::-webkit-progress-value {
    background: #6F88FC;
    border-radius: 6px;
}

.progress-bar[value="100"]::-webkit-progress-value {
    background: #7BDF9D;
}