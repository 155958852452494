#addChildGuardModal .modal-content {
  height: 370px !important;
}

#addPetGuardModal .modal-content {
  height: 370px !important;
}

.select2-container--default .select2-selection--multiple {
  height: 40px;
}

.select2-container--open {
  z-index: 9999999
}

.select2-container--default {
  width: 100% !important;
}