.our-flowers {
  max-height: 384px;
  overflow: hidden;

  .slick-track {
    @apply mb-6;
    @apply flex flex-row space-x-4 justify-center;
  }
  
  &__progress {
    position: relative;
    
    .slick-prev {
      left: 0;
    }
    
    .slick-next {
      right: 0;
    }
  }
}

.main-img-wrap {
  align-items: center;
  display: flex;
  min-height: 365px;
}

.flowers-line::-webkit-scrollbar {
  -webkit-appearance: none;
}

.flowers-line::-webkit-scrollbar:horizontal {
  height: 12px;
}

.flowers-line::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: 6px solid #6F88FC;
  background-color: #6F88FC;
}

.flowers-line::-webkit-scrollbar-track {
  background-color: #D0D2D9;
  border-radius: 6px;
}

.counter-input {
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-items: center;
  border: 0px;
  width: inherit;

  -moz-appearance: textfield;
}

.counter-input::-webkit-outer-spin-button,
.counter-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.flowers-border {
  border: 3px solid #6F88FC;
  border-radius: 8px;
}

.flowers-cost {
  font-size: 20px;
  font-weight: 600;
}

.more-views {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;

  .slick-slide {
    margin: 0 10px;
  }
  
  .slick-list {
    margin: 0 -10px;
  }

  &__image {
    border: 3px solid #fff;
    border-radius: 8px;
    overflow: hidden;
    height: 100px;
    width: 100px;
    transition: .1s all ease-in-out;
    
    &.active {
      border-color: #6F88FC;
    }

    img {
      cursor: pointer;
      object-fit: cover;
      height: 100px;
      max-width: none;
      // width: 100%;
      width: 100px;
      transition: .3s all ease-in-out;
    }
    
    &:hover {
      img {
        @apply brightness-50;
      }
    }
  }
}