.checkout-grid {
  @apply grid;
  @apply grid-cols-12;
  @apply gap-4;
  height: fit-content;
}

.grid-full {
  grid-column: span 12;
}

.grid-half {
  grid-column: span 6;
}

.grid-third {
  grid-column: span 4;
}

.card-input {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  background-color: white;
  border: 1px solid var(--Solitude, #E5E8EF);

  input {
    border: 0px;
    height: 30px;

    &:focus {
      outline: none !important;
      // border: 1px solid #E5E8EF;
      border: none;
      box-shadow: none;
    }
  }

  i {
    margin-left: 12px;
    margin-right: 8px;
    font-size: 20px;
  }

  .mm-yy {
    width: 42px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    webkit-appearance: none;
    -moz-appearance: textfield;
  }

  .cvc {
    width: 54px;
    webkit-appearance: none;
    // margin: 0;
  }
}

.save-payment {
  label {
    // color: white;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}

.billing-form {
  display: contents;
}