.dashboard_tile {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 32px rgba(165, 141, 111, 0.08);
  border-radius: 12px;
  height: 96px;
  width: 300px;
}

.dashboard_tile_full {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 32px rgba(165, 141, 111, 0.08);
  border-radius: 12px;
  height: 96px;
  width: 100%;
}

.dashboard_img {
  color: #6F88FC;
  font-size: 24px;
}

.dashboard_img_background {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  background-color: #dbe1fe;
  border-radius: 4px;
}

.activity_cart_background {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6F88FC;
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

.dashboard_tile_big {
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 0px 32px rgba(165, 141, 111, 0.08);
  border-radius: 12px;
  width: 100%;
}

.activity_link {
  display: flex;
  align-items: center;
  color: #6F88FC;
  cursor: pointer;
}

.activity_border {
  margin-left: 52px;
}

.table_wrapper {
  width: 970px;
  background-color: #FFFFFF;
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 0px 32px rgba(165, 141, 111, 0.08);
  border-radius: 12px;
}

.customers_table {
  background: #FFFFFF;
  width: 100%;
  border-collapse: collapse;
}

.customers_table tbody {
  width: 100%;
}

.customers_table tr {
  border-bottom: 1px solid #D0D2D9;
  width: 100%;
}

.customers_table tr:last-child {
  border-bottom: 0px;
}

.customers_table th {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 20px;
  padding-bottom: 16px;
  text-align: left;
  color: #888B93;
  border-bottom: 1px solid #888B93;
}

.customers_table td {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 16px;
  padding-bottom: 16px;
  @apply text-400-16-20;
}

.customers_avatar {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.products_preview {
  border-radius: 2px;
  height: 32px;
  width: 32px;
}

.blogs_preview {
  border-radius: 8px;
  height: 57px;
  width: 80px;
}

.blog_picture {
  // width: 530px;
  height: 348px;
  border-radius: 12px;
}

.video_preview {
  border-radius: 8px;
  height: 57px;
  width: 80px;
}

.video_preview_button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22px;
  height: 22px;
  @apply absolute;
}

.bulk_actions_option {
  height: 40px;
  margin-top: 12px;
  line-height: 70px;
}

.bulk_actions_select option:hover {
  background-color: #dbe1fe;
}

.green_status {
  background: #EBFAF0;
  border-radius: 20px;
  color: #7BDF9D;
  padding: 6px 12px;
  width: fit-content;
}

.yellow_status {
  background: #FFFAE7;
  border-radius: 20px;
  color: #FFC700;
  padding: 6px 12px;
  width: fit-content;
}

.gray_status {
  background: #E5E8EF;
  border-radius: 20px;
  color: #888B93;
  padding: 6px 12px;
  width: fit-content;
}

.red_status {
  background: #FADDDD;
  border-radius: 20px;
  color: #DF1919;
  padding: 6px 12px;
  width: fit-content;
}

.btn-paginator {
  display: flex;
  justify-content: center;
  align-items: center;
  @apply color-light-gray;
  height: 32px;
  width: 36px;
  background-color: #ffffff;
  font-size: 14px;
}

.btn-paginator i {
  @apply font-24px;
}

.btn-paginator i:hover {
  @apply color-indigo;
}

.search_input {
  height: 44px;
  width: 230px;
  border: 0px;
}

.search_input::placeholder {
  color: #D0D2D9;
}

.search_input_btn {
  background-color: #FFFFFF;
  color: #6F88FC;
  font-size: 20px;
  height: 45px;
  width: 44px;
}

.modal-width-700 {
  min-width: 700px;
}

.modal-width-970 {
  min-width: 970px;
}

.max-w-1170 {
  max-width: 1170px;
}

.modal-width-1170 {
  min-width: 1170px;
}

.modal-width-772 {
  min-width: 772px;
}

.modal-width-430 {
  min-width: 430px;
}

.modal_card {
  background: #FFFFFF;
  box-shadow: 0px 6px 32px rgba(190, 196, 223, 0.24);
  border-radius: 12px;
}

.modal_social_icon_box {
  width: 24px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_social_icon_box img {
  height: 24px;
}

.visibility_box_eye {
  display: flex;
  align-items: center;
  justify-content: center;
  @apply text-white;
  @apply bg-indigo;
  height: 28px;
  width: 28px;
  background: #6F88FC;
  border-radius: 4px;
  font-size: 16px;
  margin-left: 8px;
  margin-right: 12px;
}

.visibility_box {
  display: flex;
  align-items: center;
  flex-direction: row;
  background: #EEF1FF;
  border-radius: 4px;
  justify-content: space-between;
}

.featured_image_upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  border-radius: 12px;
  border: 1px dashed #6F88FC;
  @apply text-indigo;
  background: #EEF1FF;
  margin-bottom: 24px;
}

.featured_video_upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 250px;
  border-radius: 12px;
  border: 1px dashed #6F88FC;
  @apply text-indigo;
  background: #EEF1FF;
  margin-bottom: 24px;
}

.block-knowledge-video {
  @apply relative;
  width: fit-content;

  .video-img {
    @apply rounded-xl;
  }

  .play-video-button {
    // background: url(/img/video-play.svg) center no-repeat;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    position: absolute;
    width: 80px;
    height: 80px;
  }
}

.product-image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px dashed #6F88FC;
  @apply text-indigo;
  background: #EEF1FF;
  position: relative;
}

.product-image-box:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  min-height: 250px;
}

.product-image-thumbnail {
  img {
    object-fit: cover;
    border-radius: 12px;
  }
}

.product-image-thumbnail:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  min-height: 250px;
}

.colour_circle_box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 36px;
  width: 36px;

}

.colour_circle {
  cursor: pointer;
  height: 28px;
  width: 28px;
  border-radius: 50%;

  [type='radio']:checked {
    background-image: none;
  }
}

.selected_colour {
  border: 2px solid #7BDF9D;
}

.add_colour {
  @apply color-indigo;
  font-size: 28px;
}

.dashboard_details_table th {
  color: #888B93;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding-right: 16px;
  padding-bottom: 6px;
}

.dashboard_details_table td {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 6px;
}

.user_avatar {
  @apply rounded-full;
  height: 110px;
  width: 110px;
  object-fit: cover;
}

.user_no_avatar {
  background: #dbdcdf;
    border: 4px solid #fff;
  color: #FFFFFF;
  font-size: 55px;
  @apply rounded-full;
  height: 100px;
  width: 100px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_no_avatar_small {
  border: 1px solid #FFFFFF;
  background: linear-gradient(357.92deg, #6F88FC -23.65%, #D1D5FA 122.67%);
  color: #FFFFFF;
  font-size: 18px;
  @apply rounded-full;
  height: 30px;
  width: 30px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_no_avatar_medium {
  border: 1px solid #FFFFFF;
  background: linear-gradient(357.92deg, #6F88FC -23.65%, #D1D5FA 122.67%);
  color: #FFFFFF;
  font-size: 26px;
  @apply rounded-full;
  height: 48px;
  width: 48px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_no_avatar_60 {
  border: 1px solid #FFFFFF;
  background: linear-gradient(357.92deg, #6F88FC -23.65%, #D1D5FA 122.67%);
  color: #FFFFFF;
  font-size: 26px;
  @apply rounded-full;
  height: 60px;
  width: 60px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.font_18_bold {
  font-size: 18px;
  font-weight: bold;
}

.h4_20px h4 {
  @apply text-600-20-46;
}

.product-image-box img {
  border-radius: 12px;
}

.removeImageBtn {
  background-color: #EEF1FF;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 12px 0 3px;
  padding: 3px 3px 0 3px;
}

.featuredImageBtn {
  background-color: #EEF1FF;
  position: absolute;
  bottom: 0;
  border-radius: 3px;
  padding: 0 3px;
}

.timeslots_wrapper {
  width: 970px;
  background-color: #FFFFFF;
  padding: 24px;
  box-shadow: 0px 0px 32px rgba(165, 141, 111, 0.08);
  border-radius: 12px;
}

.appointment-block {
  width: 440px;
  border-radius: 12px;
  background-color: #EEF1FF;
  margin-bottom: 16px;
  padding: 16px;
}

#edit-product input {
  border-color: #D0D2D9;
}

.radio-colour {
  width: 36px;
  height: 36px;
}
