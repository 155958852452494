.cremation-burial-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #FFF;
    border-radius: 8px;
    max-width: 470px;
    padding: 97px 50px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    
    > * {
      position: relative;
      z-index: 2;
    }

    &:after {
      background-color: #fff;
      border-radius: 8px;
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      transition: .3s opacity ease-in-out;
    }

    &:hover {
      &:after {
        opacity: 0;
      }
    }
}

.cremation-burial-block:hover {
    color: #FFF;
}

.cremation-block {
    background-image: url("/img/cremation-2.png");
}

.burial-block {
    background: url("/img/burial.png");
}

.btn-gradient {
    color: #FFF;
    background: linear-gradient(357.92deg, #6F88FC -23.65%, #D1D5FA 122.67%);
    border: none;
}

.btn-gradient:hover {
    background: linear-gradient(357.92deg, #5873eb -23.65%, #D1D5FA 122.67%);
}