.dashboard-sidebar {
  @apply flex flex-col;
    @apply py-8;

  &--inner {
    width: 100%;
    @apply pr-4;
  }

  ul {
    @apply mb-4;
  }

  li {

    a {
      @apply flex items-center;
      @apply w-full;
        font-size: 14px;
        color: var(--neutral);

      i {
        @apply text-2xl;
        @apply mr-3;
      }

      b {
        background: linear-gradient(244.45deg, #6F88FC -6.98%, #A9DFE2 114.63%);
        height: 22px;
        width: 22px;
        @apply flex items-center justify-center;
        @apply font-normal;
        @apply rounded-full;
        @apply text-white text-sm;
        @apply ml-3.5;
      }

      &.active {
        @apply text-black;
          font-weight: 600;
          background-color: #6F6FD214;
          border-radius: 0 25px 25px 0;
          img {
              filter: brightness(0) saturate(100%) invert(52%) sepia(6%) saturate(5854%) hue-rotate(202deg) brightness(88%) contrast(85%);
          }

        i {
          @apply text-indigo;
        }
      }
    }
  }
}
