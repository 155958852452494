.update_input {
  border-radius: 12px;
  border: 0px;
  padding: 0px 16px;
  width: 100%;
  height: 59px;

  // outline: none !important;
  // outline-color: white;
  // outline-width: 0px;
  // border-color: white;
}

.update_input:focus {
  border-radius: 12px;
}

