.ys-redactor-wrapper {
	h1 {
		@apply text-[32px] leading-10 font-semibold text-neutral-text;
	}

	h2 {
		@apply text-2xl font-semibold text-neutral-text;
	}

	h3 {
		@apply text-xl font-semibold text-neutral-text;
	}

	h4 {
		@apply text-base font-semibold text-neutral-text;
	}

	h5 {
		@apply text-sm font-semibold text-neutral-text;
	}

	h6 {
		@apply text-xs font-semibold text-neutral-text;
	}

	ul {
		@apply list-inside list-disc;

		li {
			p {
				@apply mb-0 inline-block;
			}
		}

		&[data-type='taskList'] {
			@apply list-none;

			li {
				@apply flex flex-row  items-center mb-2 last:mb-0;

				label {
					@apply mr-3;
				}
			}
		}
	}
	ol {
		@apply list-inside list-decimal;

		li {
			p {
				@apply mb-0 inline-block;
			}
		}
	}
	p {
		@apply text-neutral-weak text-sm mb-0;

		@screen md {
			@apply text-base;
		}
	}

	blockquote {
		@apply border-l-2 border-gray-200 pl-3;
	}
}
