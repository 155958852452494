header {
  .link-avatar {
    @apply flex items-center;
    
    img {
      @apply border border-indigo border-solid;
      @apply rounded-full;
      @apply h-7.5;
    }
  }
}

