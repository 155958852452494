.customer_box {
  box-shadow: 0px 6px 32px rgb(190 196 223 / 24%);
  border-radius: 12px;
  padding-left: 20px;
  margin-top: 24px !important;
}

.search_input_icon {
  background-color: #FFFFFF;
  color: #6F88FC;
  font-size: 26px;
  height: 45px;
  width: 44px;
  padding-top: 10px;
  padding-left: 10px;
  margin-left: -44px;
}