#lifetime-range {
    .irs-grid-pol {
        &.minor-tick {
            height: 50%;
            background-color: gray;
        }
    }

    .irs-single,
    .irs-from,
    .irs-to,
    .irs-min,
    .irs-max {
        visibility: hidden;
    }

    .irs--flat {
        &.irs-with-grid {
            height: 40px;
        }

        .irs-bar {
            background-color: rgba(111, 111, 210, 0.25);
            cursor: pointer;
            top: 22px;
            height: 40px;
            top: 0;
        }

        .irs-line {
            top: 0;
            height: 40px;
            background-color: rgba(111, 111, 210, 0.08);
            border-radius: 8px;
            border: 1px solid rgba(111, 111, 210, 0.25);
            box-sizing: border-box;
            overflow: hidden;
        }

        .irs-handle {
            background-image:  url("../../svg/control.svg");
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 50%;
            cursor: ew-resize;
            top: 50%;
            transform: translateY(-50%);
            height: 16px;
            width: 16px;

            > i:first-child {
                top: -11px;
                height: 38px;
                background-color: #6F6FD2;
            }

            &:hover {
                > i:first-child {
                    background-color: #6F6FD2;
                }
            }
        }

        .irs-grid-pol {
            background-color: rgba(111, 111, 210, 0.25);
            top: auto;
            bottom: 0;
        }

        .irs-grid-text {
            background-color: #ECECF3;
            color: #5F6877;
            padding: 2px 0 1px;
            bottom: auto;
            font-size: 11px;
            line-height: 14px;
            top: 12px;
        }

        .irs-from,
        .irs-to,
        .irs-single {
            background-color: #6F6FD2;
            border-radius: 2px;
            padding: 0px 3px;
            top: 13px;
            font-size: 11px;
            z-index: 3;

            &:before {
                display: none;
                content: none;
            }
        }

        .irs-from {
          margin-left: -34px;
        }

        .irs-to {
          margin-left: 34px;
        }
    }

    .irs-min,
    .irs-max {
        display: none;
    }

    .irs-grid {
        height: 40px;
    }

    .irs-grid-pol {
        &.small {
            height: 12px;
        }

        &:not(.small) {
            height: 40px;
            width: 2px;
        }
    }

    .irs-grid-pol,
    .irs-grid-text {
        &[style*="left: 0%"],
        &[style*="left: 100%"] {
            display: none;
        }
    }
}