.deal_card {
    background-color: #ffffff;
    box-shadow: 0px 0px 60px 10px rgba(165, 141, 111, 0.16);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
}

.deal_card img {
    border-radius: 12px;
    margin-bottom: 20px;
}

.deal_card a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6F88FC;
    margin-bottom: 6px;
}

.deal_card span {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 6px;
}

.deal_card p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}